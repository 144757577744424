///Validation REG EXP
var emailRE = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

// Initialize Firebase
var config = {
    apiKey: "AIzaSyANGakK8v7BaFYt-i451GzLlOACa_XuqBc",
    authDomain: "bitlab-c591e.firebaseapp.com",
    databaseURL: "https://bitlab-c591e.firebaseio.com",
    projectId: "bitlab-c591e",
    storageBucket: "bitlab-c591e.appspot.com",
    messagingSenderId: "674553807524"
};
firebase.initializeApp(config);
var ordersRef = firebase.database().ref('/orders')

Vue.component('modal', {
    template: '#modal-template'
})

var app = new Vue({
    mounted() {
        this.classMenuBg = 'transparent'
        window.addEventListener('scroll', this.onScroll);
    },
    el: '#webApp',
    data () {
        return {
            classMenuBg: 'transparent',
            showModal: false,
            paquete: 'Bitlab Básico',
            preOrdenFrm: {
                'lab': '',
                'paquete': '',
                'rfc': '',
                'razon_social': '',
                'nombre_contacto': '',
                'telefono': '',
                'email': '',
                'calle': '',
                'no_ext': '',
                'no_int': '',
                'colonia': '',
                'ciudad': '',
                'estado': '',
                'cp': '',
            }
        }
    },
    computed: {
        validation () {
            return {
                name: !!this.newUser.name.trim(),
                email: emailRE.test(this.preOrdenFrm.email)
            }
        },
        isValid () {
            var validation = this.validation
            return Object.keys(validation).every(function (key) {
                return validation[key]
            })
        }
    },
    firebase: {
        orders: ordersRef
    },
    methods:{
        onScroll:function(){
            var scrollY = window.scrollY;
            if (scrollY >= 200) {
                this.classMenuBg = 'grey darken-3'
            } else if (scrollY == 0) {
                this.classMenuBg = 'transparent'
            }
        },

        showMod (paq) {
            this.paquete = paq;
            this.showModal = true;
        },

        makeOrder () {
            this.preOrdenFrm.paquete = this.paquete;
            ordersRef.push(this.preOrdenFrm)
            this.cleanForm()
        },

        cancelOrder () {
            this.showModal = false;
            this.cleanForm()
        },

        cleanForm () {
            this.preOrdenFrm.lab = ''
            this.preOrdenFrm.rfc = ''
            this.preOrdenFrm.razon_social = ''
            this.preOrdenFrm.nombre_contacto = ''
            this.preOrdenFrm.telefono = ''
            this.preOrdenFrm.email = ''
            this.preOrdenFrm.calle = ''
            this.preOrdenFrm.no_ext = ''
            this.preOrdenFrm.no_int = ''
            this.preOrdenFrm.colonia = ''
            this.preOrdenFrm.ciudad = ''
            this.preOrdenFrm.estado = ''
            this.preOrdenFrm.cp = ''
        }
    }
})
